import { ModuleWithProviders, NgModule } from '@angular/core';

export function lastLoginServiceFactory($injector: any) {
    return $injector.get('LastLogin');
}
export function m2PubSubServiceFactory($injector: any) {
    return $injector.get('m2PubSub');
}

@NgModule({})
export class LastLoginModule {
    public static forRoot(): ModuleWithProviders<LastLoginModule> {
        return {
            ngModule: LastLoginModule,
            providers: [
                {
                    provide: 'LastLogin',
                    useFactory: lastLoginServiceFactory,
                    deps: ['$injector'],
                },
                {
                    provide: 'm2PubSub',
                    useFactory: m2PubSubServiceFactory,
                    deps: ['$injector'],
                },
            ],
        };
    }
}
