import { ModuleWithProviders, NgModule } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PokerLeaderboardFeedPageMatrixBootstrapService } from './poker-leaderboard-feed-bootstrap.service';

@NgModule({
    imports: [],
})
export class PokerLeaderboardFeedModule {
    static forRoot(): ModuleWithProviders<PokerLeaderboardFeedModule> {
        return {
            ngModule: PokerLeaderboardFeedModule,
            providers: [runOnAppInit(PokerLeaderboardFeedPageMatrixBootstrapService)],
        };
    }
}
