import { ModuleWithProviders, NgModule } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { CasinoMenuActionsBootstrapService } from './casino-menu-actions-bootstrap.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
})
export class CasinoMenuModule {
    public static forRoot(): ModuleWithProviders<CasinoMenuModule> {
        return {
            ngModule: CasinoMenuModule,
            providers: [runOnAppInit(CasinoMenuActionsBootstrapService)],
        };
    }
}
