import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'poker-leaderboard-feed',
    templateUrl: 'poker-leaderboard-feed.component.html',
    standalone: true,
    imports: [NgFor, NgIf, NgClass, ReactiveFormsModule, FormsModule, MatTableModule, MatPaginatorModule],
})
export class PokerLeaderboardFeedComponent extends PCComponent implements OnInit {
    leaderboardFeed: any;
    contentParameters: any;
    promoData: any;
    selectedPromo: any;
    buttonParameters: any;
    filterValue: any = '';
    checkPromoData: boolean = true;
    displayedColumns: string[] = ['rank', 'points', 'screenName', 'prizes'];
    dataSource = new MatTableDataSource<any>();
    currentDate: any;
    getDate: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @HostBinding()
    get class() {
        return this.createClass('leaderboard');
    }

    ngOnInit() {
        this.leaderboardFeed = this.item;

        this.contentParameters = this.leaderboardFeed.parameters;
        this.buttonParameters = this.leaderboardFeed.contentParameters;
        this.getPromo(this.buttonParameters[0]);
        this.getDateFormat();
    }

    getPromo(item: any) {
        this.selectedPromo = item;
        this.filterValue = '';

        this.promoData = this.leaderboardFeed.leaderboardFeedData[item.promoId];

        if (this.promoData === undefined) {
            this.checkPromoData = false;
            this.dataSource = new MatTableDataSource();
            this.paginator.pageIndex = 0;
            this.dataSource.paginator = this.paginator;
        } else {
            this.checkPromoData = true;
            this.dataSource = new MatTableDataSource(this.promoData.details);
            this.paginator.pageIndex = 0;
            this.dataSource.paginator = this.paginator;
        }
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue?.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    refreshPromoData() {
        window.location.reload();
    }

    getDateFormat() {
        this.getDate = new Date();
        this.currentDate = this.getDate.toUTCString().slice(0);
    }
}
