import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { provideLoaders } from '@frontend/loaders';
import { AppComponent, bootloader, provideVanillaCore } from '@frontend/vanilla/core';

import { AppModule } from './app.module';
import { appRoutes } from './app.routes';

if (process.env.NODE_ENV === 'production') {
    enableProdMode();
}

performance.mark('app-boot-start');
bootloader().then(() => {
    performance.mark('app-client-config-ready');
    performance.measure('client-config', 'app-boot-start', 'app-client-config-ready');
    bootstrapApplication(AppComponent, {
        providers: [
            provideRouter(appRoutes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })),
            // { provide: RouteReuseStrategy, useClass: CasinoRouteReuseStrategy },
            provideAnimationsAsync(),
            provideVanillaCore(),
            provideLoaders(),
            importProvidersFrom(AppModule),
        ],
    })
        .then(() => {
            performance.mark('app-boot-end');
            performance.measure('app-boot', 'app-boot-start', 'app-boot-end');
        })
        .catch();
});
