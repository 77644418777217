import { ModuleWithProviders, NgModule } from '@angular/core';

export function m2PubSubServiceFactory($injector: any) {
    return $injector.get('m2PubSub');
}

@NgModule({})
export class ScreenShotModule {
    public static forRoot(): ModuleWithProviders<ScreenShotModule> {
        return {
            ngModule: ScreenShotModule,
            providers: [
                {
                    provide: 'm2PubSub',
                    useFactory: m2PubSubServiceFactory,
                    deps: ['$injector'],
                },
            ],
        };
    }
}
