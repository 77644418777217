import { Routes } from '@angular/router';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';
import { ClientConfigProductName, routeData } from '@frontend/vanilla/core';

import { HomeLobbyModComponent } from './homelobbymod';
import { CasinoHomePageGuard } from './homelobbymod/casino-home-page.gaurd';

export const appRoutes: Routes = [
    {
        path: '',
        component: HomeLobbyModComponent,
        canActivate: [CasinoHomePageGuard],
        data: {
            ...routeData({ allowAnonymous: true }),
            product: 'host',
            segment: 'main',
        },
    },
    {
        path: 'game-info/:gameId',
        component: HomeLobbyModComponent,
        canActivate: [CasinoHomePageGuard],
        data: routeData({ allowAnonymous: true }),
    },
    {
        path: '{culture}',
        data: {
            ...routeData({ allowAnonymous: true }),
            product: 'host',
            segment: 'main',
        },
        children: [
            //...VANILLA_ROUTES,
            ...VANILLA_LAZY_ROUTES,
            // gamificationWidgetRoute,
            {
                path: 'games/gamelogs',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./game-logs-ctrl/game-logs-ctrl.module').then((mod) => mod.GameLogsCtrlModule),
                    },
                ],
            },
            {
                path: 'games/p/**',
                loadChildren: () => import('./publicPagesWrapper/publicPagesWrapper.module').then((mod) => mod.PublicPagesWrapperModule),
                data: {
                    publicPageRoot: 'MobileCasino-v1.0/PublicPages/',
                },
            },
            {
                path: 'casinowidget',
                children: [
                    {
                        path: 'rp-gyml',
                        loadChildren: () => import('./iframe-rp-gyml/iframe-rp-gyml-routing.module').then((mod) => mod.IFrameRpGymlRoutingModule),
                    },
                    {
                        path: 'rpfavwidget',
                        loadChildren: () => import('./iframe-rp-fav-widget/iframe-rp-fav-routing.module').then((mod) => mod.IFrameRpFavRoutingModule),
                    },
                    {
                        path: 'subcategory-footer',
                        loadChildren: () =>
                            import('./iframe-subcategory-footer/iframe-subcategory-footer-routing.module').then(
                                (mod) => mod.IframeSubCategoryAsFooterRoutingModule,
                            ),
                    },
                ],
            },
            {
                path: 'blog',
                children: [
                    {
                        path: '**',
                        loadChildren: () => import('./blog/blog.module').then((mod) => mod.BlogModule),
                    },
                ],
            },
            {
                path: 'p',
                children: [
                    {
                        path: '**',
                        loadChildren: () => import('./publicPagesWrapper/publicPagesWrapper.module').then((mod) => mod.PublicPagesWrapperModule),
                        data: {
                            waitForPageViewData: true,
                            publicPageRoot: 'MobilePortal-v1.0/PublicPages/',
                        },
                    },
                ],
            },
            {
                path: 'foxy-den',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./publicPagesWrapper/publicPagesWrapper.module').then((mod) => mod.PublicPagesWrapperModule),
                        data: {
                            waitForPageViewData: true,
                            publicPageRoot: 'MobileCasino-v1.0/foxy-den/root',
                        },
                    },
                    {
                        path: '**',
                        loadChildren: () => import('./publicPagesWrapper/publicPagesWrapper.module').then((mod) => mod.PublicPagesWrapperModule),
                        data: {
                            waitForPageViewData: true,
                            publicPageRoot: 'MobileCasino-v1.0/foxy-den/',
                        },
                    },
                ],
            },
            {
                path: 'foxy-space',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./publicPagesWrapper/publicPagesWrapper.module').then((mod) => mod.PublicPagesWrapperModule),
                        data: {
                            waitForPageViewData: true,
                            publicPageRoot: 'MobileCasino-v1.0/foxy-space/root',
                        },
                    },
                    {
                        path: '**',
                        loadChildren: () => import('./publicPagesWrapper/publicPagesWrapper.module').then((mod) => mod.PublicPagesWrapperModule),
                        data: {
                            waitForPageViewData: true,
                            publicPageRoot: 'MobileCasino-v1.0/foxy-space/',
                        },
                    },
                ],
            },
            {
                path: 'game-info/:gameId',
                component: HomeLobbyModComponent,
            },
            {
                path: ':product/p',
                children: [
                    {
                        path: '**',
                        loadChildren: () => import('./publicPagesWrapper/publicPagesWrapper.module').then((mod) => mod.PublicPagesWrapperModule),
                        data: {
                            publicPageRoot: 'MobileCasino-v1.0/PublicPages/',
                        },
                    },
                ],
            },
            {
                path: '',
                loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
                data: routeData({ allowAnonymous: true }),
            },

            {
                path: 'casino',
                redirectTo: 'games',
            },
            {
                path: 'comppoints',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./comp-points/comp-points.module').then((mod) => mod.CompPointsModule),
                    },
                ],
            },
            {
                path: 'account/freespins',
                redirectTo: 'casino/myfreespins',
            },
            {
                path: 'account/freespins/history',
                redirectTo: 'casino/myfreespins/history',
            },

            {
                path: 'account/freespins',
                redirectTo: 'casino/myfreespins',
            },
            {
                path: 'account/freespins/history',
                redirectTo: 'casino/myfreespins/history',
            },
            {
                path: ':product/launchng',
                loadChildren: () => import('./sections/play-redirect/play-redirect.module').then((mod) => mod.PlayRedirectModule),
            },
            {
                path: 'g/:globaljackpotpath',
                loadChildren: () => import('./mod-casino/global-jackpot/global-jackpot.module').then((mod) => mod.GlobalJackpotModule),
            },

            {
                path: ':product/launchEmbedded/:gameId',
                loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
            },
            {
                path: ':product/c/:categoryType',
                loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
                data: routeData({ allowAnonymous: true }),
            },

            {
                path: ':product/casinowidget/:categoryType',
                loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
                data: routeData({ allowAnonymous: true }),
            },

            {
                path: ':product/game-info/:gameId',
                loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
            },

            {
                path: ':product/c/:categoryType/game-info/:gameId',
                loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
            },

            {
                path: ':product/myfreespins',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./sections/freespins/my-freespins/my-freespins-routing').then((mod) => mod.myFreespinsRoutes),
                    },
                ],
            },
            {
                path: ':product/gamedetail/:gameId',
                loadChildren: () => import('./game-detail/game-detail.module').then((mod) => mod.GameDetailModule),
            },
            {
                path: ':product/discover',
                loadChildren: () => import('./explore-page/explore-page-routing.module').then((mod) => mod.ExplorePageRoutingModule),
            },
            {
                path: ':product',
                loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
                data: routeData({ allowAnonymous: true }),
            },
            {
                path: 'account/freespins',
                redirectTo: 'casino/myfreespins',
            },
            {
                path: 'account/freespins/history',
                redirectTo: 'casino/myfreespins/history',
            },
            {
                path: 'casino',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
                        data: routeData({ allowAnonymous: true }),
                    },

                    {
                        path: 'teasers',
                        loadComponent: () => import('@casinocore/platform').then(({ ResizableTeasersComponent }) => ResizableTeasersComponent),
                        data: routeData({ allowAnonymous: true }),
                    },
                    {
                        path: 'gamelogs',
                        children: [
                            {
                                path: '',
                                loadChildren: () => import('./game-logs-ctrl/game-logs-ctrl.module').then((mod) => mod.GameLogsCtrlModule),
                            },
                        ],
                    },
                    {
                        path: 'unavailablegame',
                        loadChildren: () => import('./sections/unavailable-Games/unavailableGames.module').then((mod) => mod.UnAvailableGamesModule),
                    },
                    {
                        path: 'category/:categoryType',
                        loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
                        data: routeData({ allowAnonymous: true }),
                    },
                    {
                        path: 'gamedetail/:gameId',
                        loadChildren: () => import('./game-detail/game-detail.module').then((mod) => mod.GameDetailModule),
                    },
                    {
                        path: 'launchng',
                        loadChildren: () => import('./sections/play-redirect/play-redirect.module').then((mod) => mod.PlayRedirectModule),
                    },
                    {
                        path: 'launchEmbedded/:gameId',
                        loadComponent: () => import('./homelobbymod/homelobbymod.component').then((m) => m.HomeLobbyModComponent),
                        data: routeData({ authorized: true }),
                    },
                    {
                        path: 'unfinishedgame',
                        loadChildren: () => import('./sections/unfinishedGames/unfinishedGames.module').then((mod) => mod.UnfinishedGamesModule),
                    },
                    {
                        path: 'p',
                        loadChildren: () => import('@frontend/vanilla/features/public-page').then((m) => m.ROUTES),
                        data: {
                            publicPageRoot: 'MobileCasino-v1.0/PublicPages/',
                            product: ClientConfigProductName.CASINO,
                        },
                    },
                    {
                        path: 'slots',
                        loadChildren: () => import('@frontend/vanilla/features/public-page').then((m) => m.ROUTES),
                        data: {
                            publicPageRoot: 'MobileCasino-v1.0/PublicPages/slots',
                            product: ClientConfigProductName.CASINO,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '**',
        loadChildren: () => import('@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
    },
];
