import { ModuleWithProviders, NgModule } from '@angular/core';

export function sUtilsFactory($injector: any) {
    return $injector.get('sUtils');
}

@NgModule({})
export class OpeninghoursModule {
    public static forRoot(): ModuleWithProviders<OpeninghoursModule> {
        return {
            ngModule: OpeninghoursModule,
            providers: [
                {
                    provide: 'sUtils',
                    useFactory: sUtilsFactory,
                    deps: ['$injector'],
                },
            ],
        };
    }
}
