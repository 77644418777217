import { Injectable } from '@angular/core';

import { MenuActionsService, NavigationService, OnAppInit } from '@frontend/vanilla/core';

import { McPageClientConfig } from '../client-config';

@Injectable()
export class CasinoMenuActionsBootstrapService implements OnAppInit {
    constructor(
        private menuActions: MenuActionsService,
        private mcPage: McPageClientConfig,
        private navigation: NavigationService,
    ) {}

    onAppInit() {
        this.menuActions.register('gotoHome', () => {
            this.navigation.goTo(this.mcPage?.defaultPage?.url);
        });
    }
}
