import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { HammerModule } from '@angular/platform-browser';

import { STORE_PREFIX, runOnAppInit } from '@frontend/vanilla/core';
import { GlobalSearchModule } from '@globalsearch/productstub';
import player from 'lottie-web';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';

import { ApiModule } from './api';
import { CasinoBootstrapModule } from './bootstrap';
import { CasinoMenuModule } from './casino-menu';
import { CasinoClientConfigModule } from './client-config';
import { HostAppBootstrapper } from './host-app';
import { LastLoginModule } from './lastlogin';
import { PokerLeaderboardFeedModule } from './poker-leaderboard-feed/poker-leaderboard-feed.module';
import { EmbeddedTeaserModule } from './sections/embedded-teaser/embedded-teaser.module';
import { OpeninghoursModule } from './sections/openinghours/openinghours.module';
import { ScreenShotModule } from './sections/screen-shot/screen-shot.module';

@NgModule({
    imports: [
        MatDialogModule,
        CasinoClientConfigModule.forRoot(),
        ApiModule.forRoot(),
        LastLoginModule.forRoot(),
        OpeninghoursModule.forRoot(),
        EmbeddedTeaserModule.forRoot(),
        ScreenShotModule.forRoot(),
        CasinoMenuModule.forRoot(),
        CasinoBootstrapModule.forRoot(),
        GlobalSearchModule.forRoot(),
        PokerLeaderboardFeedModule.forRoot(),
        HammerModule,
        LottieModule.forRoot({ player: () => player }),
        LottieCacheModule.forRoot(),
        NgCircleProgressModule.forRoot({
            // set defaults here
            backgroundPadding: -3,
            radius: 24,
            space: -4,
            outerStrokeGradient: false,
            outerStrokeWidth: 4,
            outerStrokeColor: '#82c248',
            innerStrokeColor: '#585858',
            innerStrokeWidth: 4,
            title: '',
            animateTitle: false,
            animationDuration: 1000,
            showSubtitle: false,
            showUnits: false,
            showBackground: false,
            clockwise: false,
            startFromZero: false,
            animation: false,
            titleColor: '#000000',
            titleFontSize: '18',
            titleFontWeight: '900',
        }),
        LazyLoadImageModule,
    ],
    providers: [
        { provide: STORE_PREFIX, useValue: 'casino.' },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        runOnAppInit(HostAppBootstrapper),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
