import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';
import { rxEffects } from '@rx-angular/state/effects';

import { PokerLeaderboardFeedComponent } from './poker-leaderboard-feed.component';

@Injectable()
export class PokerLeaderboardFeedPageMatrixBootstrapService implements OnAppInit {
    private readonly rxEffects = rxEffects();
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.rxEffects.register(this.pageMatrixService.whenReady, () => {
            this.pageMatrixService.registerComponent('leaderboardfeedtemplate', PokerLeaderboardFeedComponent);
        });
    }
}
