import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { DslService, NavigationService } from '@frontend/vanilla/core';
import { first, map } from 'rxjs';

import { FeaturesClientConfig, LabelHostHomePageClientConfig } from '../client-config';

@Injectable({
    providedIn: 'root',
})
export class CasinoHomePageGuard implements CanActivate {
    constructor(
        private featuresClientConfig: FeaturesClientConfig,
        private labelHostHomePageClientConfig: LabelHostHomePageClientConfig,
        private dslService: DslService,
        private navigation: NavigationService,
    ) {}

    canActivate(): boolean {
        if (this.featuresClientConfig.isVanillaHomePageEnabled) {
            if (this.labelHostHomePageClientConfig.isEnabled) {
                this.dslService.evaluateExpression(this.labelHostHomePageClientConfig.isEnabledCondition).pipe(
                    first(),
                    map((isEnabledCondition) => {
                        if (isEnabledCondition) {
                            return true;
                        } else {
                            return this.navigation.goTo('/games');
                        }
                    }),
                );
            }
        }
        return true;
    }
}
