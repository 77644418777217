import { ModuleWithProviders, NgModule } from '@angular/core';

export function navigatorServiceFactory($injector: any) {
    return $injector.get('Navigator');
}
export function m2PubSubServiceFactory($injector: any) {
    return $injector.get('m2PubSub');
}

@NgModule({})
export class EmbeddedTeaserModule {
    public static forRoot(): ModuleWithProviders<EmbeddedTeaserModule> {
        return {
            ngModule: EmbeddedTeaserModule,
            providers: [
                {
                    provide: 'Navigator',
                    useFactory: navigatorServiceFactory,
                    deps: ['$injector'],
                },
                {
                    provide: 'm2PubSub',
                    useFactory: m2PubSubServiceFactory,
                    deps: ['$injector'],
                },
            ],
        };
    }
}
