<div id="leaderboard" class="leaderboard-container">
    <!-- Button List -->
    <div class="mb-2">
        <ng-container *ngFor="let item of buttonParameters">
            <button
                *ngIf="item.promoId !== null"
                [ngClass]="{ 'tab-btn-item-active': selectedPromo == item }"
                (click)="getPromo(item)"
                class="btn-sm btn tab-btn-item d-inline mr-1 mb-1">
                {{ item.buttonTitle }}
            </button>
        </ng-container>
        <button class="btn-sm btn d-inline mr-1 float-right refresh-bth mt-2" (click)="refreshPromoData()">
            <span class="theme-refresh text-capitalize">Refresh</span>
        </button>
    </div>
    <div [ngClass]="{ 'd-none-visible': !checkPromoData }">
        <!-- Search Filter -->
        <div class="mb-3">
            <input (keyup)="applyFilter($event.target.value)" [(ngModel)]="filterValue" class="form-filter-control" placeholder="Search" />
            <p class="mb-1 float-right mt-4 pr-1">Last updated time: {{ currentDate }}</p>
        </div>

        <!-- Table -->
        <div class="mat-table-theme">
            <table mat-table [dataSource]="dataSource" class="table table-bordered table-striped text-center mb-4">
                <caption></caption>
                <!-- rank Column -->
                <ng-container matColumnDef="rank">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Rank</th>
                    <td mat-cell *matCellDef="let element">{{ element?.rank }}</td>
                </ng-container>

                <!-- points Column -->
                <ng-container matColumnDef="points">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Points</th>
                    <td mat-cell *matCellDef="let element" class="text-left">{{ element?.points }}</td>
                </ng-container>

                <!-- screenName Column -->
                <ng-container matColumnDef="screenName">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Screen Name</th>
                    <td mat-cell *matCellDef="let element" class="text-left">
                        {{ element?.screenName?.charAt(0).toUpperCase() + element?.screenName?.substr(1).toLowerCase() }}
                    </td>
                </ng-container>

                <!-- prizes Column -->
                <ng-container matColumnDef="prizes">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Prizes</th>
                    <td mat-cell *matCellDef="let element">{{ element?.prizes['en-US'] }}</td>
                </ng-container>

                <tr mat-header-row class="thead-dark" *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <div class="message-panel mt-4 mb-3" *ngIf="!checkPromoData">
        <div class="theme-info-i warning">
            <div class="cms-container">
                <h3>
                    <span>Data not available for </span><strong>{{ selectedPromo.buttonTitle }}</strong>
                </h3>
            </div>
        </div>
    </div>
</div>
